import { Theme } from '@mui/material/styles'

const Title = {
  fontFamily: 'Dosis',
  fontSize: { md: '16px', lg: '18px', xl: '20px' },
  fontWeight: 600,
  textTransform: 'uppercase',
  mr: 'auto',
}

const NoDataTitle = {
  fontFamily: 'Dosis',
  fontSize: { md: '14px', lg: '16px', xl: '18px' },
  fontWeight: 500,
  textTransform: 'uppercase',
  margin: { md: '40px auto', lg: '45px auto', xl: '55px auto' },
}

const TableContainer = {
  '.MuiTable-root': {
    mt: 2,
    whiteSpace: 'nowrap',
    '.MuiTableHead-root': {
      '.MuiTableRow-root': {
        '.MuiTableCell-root': {
          color: '#7E8BA6',
          fontFamily: 'Source Sans Pro',
          fontSize: '14px',
          fontWeight: 700,
          textTransform: 'uppercase',
          padding: { md: '0 10px', lg: '0 11px', xl: '0 13px' },
          border: 'none',
          lineHeight: { md: '15px', lg: '16px', xl: '18px' },
          '&:first-child': {
            paddingLeft: 0,
          },
          '&:last-child': {
            paddingRight: 0,
          },
        },
      },
    },
    '.MuiTableBody-root': {
      '.MuiTableRow-root': {
        '.MuiTableCell-root': {
          fontFamily: 'Source Sans Pro',
          fontSize: { md: '15px', lg: '15px', xl: '16px' },
          fontWeight: 400,
          color: '#1C2842',
          padding: { md: '13px 10px', lg: '14px 11px', xl: '16px 13px' },
          lineHeight: { md: '15px', lg: '16px', xl: '18px' },
          '&.long-width': {
            maxWidth: '435px',
            minWidth: '435px',
            whiteSpace: 'wrap',
          },
          a: {
            color: '#1C2842',
          },
          '&:first-child': {
            paddingLeft: 0,
          },
          '&:last-child': {
            paddingRight: 0,
          },
        },
      },
    },
  },
}

const ButtonGroup = {
  ml: 1,
  backgroundColor: '#e9ecf1',
  borderRadius: '8px',
  overflow: 'hidden',
  boxShadow: 'none',
}

const Button = {
  fontFamily: 'Source Sans Pro',
  fontSize: { md: '14px', lg: '15px', xl: '16px' },
  fontWeight: 400,
  backgroundColor: 'transparent',
  textTransform: 'capitalize',
  color: '#1C2842',
  padding: { md: '3px 10px', lg: '4px 12px', xl: '6px 16px' },
  borderRadius: '8px',
  '&.MuiButtonGroup-firstButton, &.MuiButtonGroup-lastButton': {
    borderRadius: '8px',
    border: 0,
  },
  '&:hover': {
    backgroundColor: '#e9ecf1',
    color: '#1c2842cf',
  },
}

const ActiveButton = {
  backgroundColor: '#2257C5',
  color: '#fff',
  '&:hover': {
    backgroundColor: '#2257C5',
    color: '#fff',
  },
}

const DatePickerContainer = {
  ml: 3,
  '& > div': {
    borderColor: '#DAD8DF',
    'img.calendarIcon': {
      width: '20px',
      height: '20px',
      marginInlineStart: '5px',
    },
    '.react-datepicker-wrapper': {
      '.react-datepicker__input-container': {
        input: {
          width: '50px',
          fontWeight: 400,
          fontSize: '16px',
        },
      },
    },
    '.react-datepicker__tab-loop': {
      '.react-datepicker-popper': {
        position: 'absolute',
        left: 0,
        top: '45px !important',
        margin: 0,
        transform: 'none !important',
        width: 'calc(100% + 1px)',
        '.react-datepicker': {
          borderColor: '#DAD8DF',
          width: '100%',
          '.react-datepicker__year': {
            '.react-datepicker__year-wrapper': {
              width: '100%',
              maxWidth: '100%',
              justifyContent: 'center',
              '.react-datepicker__year-text': {
                fontFamily: 'Source Sans Pro',
                fontSize: '15px',
                flex: '0 0 45%',
                '&:hover': {
                  backgroundColor: '#e9ecf1',
                },
                '&.react-datepicker__year-text--selected': {
                  backgroundColor: '#2257C5',
                },
              },
            },
          },
        },
      },
    },
  },
}

const SelectorsTitle = {
  fontFamily: 'Source Sans Pro',
  fontSize: '16px',
  fontWeight: 400,
}

const TooltipText = {
  fontFamily: 'Source Sans Pro',
  fontSize: '16px',
  fontWeight: 400,
  color: 'rgba(28, 40, 66, 1)',
  lineHeight: '18px',
}

const styles = {
  Title,
  NoDataTitle,
  TableContainer,
  ButtonGroup,
  Button,
  ActiveButton,
  DatePickerContainer,
  SelectorsTitle,
  TooltipText,
}

export const fontFamily = 'Source Sans Pro'

export const FilterButton = (theme: Theme) => ({
  border: `1px solid ${theme.palette.border.button}`,
  minWidth: '32px',
  height: '32px',
  padding: '8px',
  borderRadius: '8px',
})

export const ActiveDepartments = (theme: Theme) => ({
  fontFamily: 'Dosis',
  fontSize: '16px',
  display: 'inline-block',
  height: 32,
  minWidth: 32,
  maxWidth: 500,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  marginInlineEnd: '10px',
  borderRadius: '8px',
  border: `1px solid ${theme.palette.border.button}`,
  padding: '4px 8px',
})

export default styles
