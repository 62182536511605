import React from 'react'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import TableText from 'components/common/TableText'
import { MRT_ColumnDef } from 'material-react-table'
import { TABLE_TEXT_TYPES } from 'utils/types'
import { useBaseCellStyles } from 'components/verticals/VerticalTableBase/useBaseCellStyles'
import { NOT_AVAILABLE } from 'utils/constants'
import Tooltip from 'components/Tooltip'
import { OffshoreEntityForTable } from 'containers/OffshoreTablePage/types'
import { isNumber } from 'lodash'
import { formattedDate } from 'utils/formatters'
import ReportedStatus from 'components/ReportedStatus/ReportedStatus'
import NoData from 'components/NoData/NoData'

export const useOffshoreTableColumns = () => {
  const baseCellStyles = useBaseCellStyles()

  const tooltipValues = {
    profileName: 'The profile name as appears in the web platform',
    verifiedName: 'The full name of the profile owner',
    tin: 'The official tax identification number of the identified entity',
    reportStatus:
      'The status of the tax reporting in each tax year after comparing the tax return reportings with the documented revenue',
    firstActivity: 'The date of the first documented business activity as identified by IVIX',
    lastActivity: 'The date of the last documented business activity as identified by IVIX',
    caseID: 'Unique IVIX id number for the case',
  }

  const columns = React.useMemo<MRT_ColumnDef<OffshoreEntityForTable>[]>(
    () => [
      {
        header: 'Identification Details',
        Header: <TableText textType={TABLE_TEXT_TYPES.groupHeader}>Identification details</TableText>,
        columns: [
          {
            id: 'revenueSources.profileName.keyword',
            header: 'Profile Names',
            enableSorting: false,
            Header: (
              <Tooltip title={tooltipValues.profileName}>
                <TableText textType={TABLE_TEXT_TYPES.columnHeader}>Profile Names</TableText>
              </Tooltip>
            ),
            muiTableBodyCellProps: { sx: { ...baseCellStyles } },
            size: 380,
            accessorFn: (item: OffshoreEntityForTable) => {
              const profileNames = item?.revenueSources?.reduce((currentNames, { profileName }) => {
                if (!currentNames.includes(profileName)) {
                  currentNames.push(profileName)
                }
                return currentNames
              }, [] as string[])
              return (
                <Box>
                  <Stack direction='row'>
                    {profileNames?.length === 0 && <></>}
                    {profileNames?.length === 1 && <TableText>{profileNames[0]}</TableText>}
                    {profileNames?.length > 1 && (
                      <Tooltip title={profileNames.join(', ')}>
                        <Stack direction='row' spacing={1}>
                          <TableText>{profileNames[0]}</TableText>
                          <TableText>{`+ ${profileNames.length - 1}`}</TableText>
                        </Stack>
                      </Tooltip>
                    )}
                  </Stack>
                </Box>
              )
            },
          },
          {
            id: 'entityDetails.name.keyword',
            header: 'Verified Name',
            Header: (
              <Tooltip title={tooltipValues.verifiedName}>
                <TableText textType={TABLE_TEXT_TYPES.columnHeader}>Verified Name</TableText>
              </Tooltip>
            ),
            muiTableBodyCellProps: {
              sx: baseCellStyles,
            },
            size: 180,
            accessorFn: item => (item?.entityDetails?.name ? <TableText>{item.entityDetails.name}</TableText> : <></>),
          },
          {
            id: 'entityDetails.tin',
            header: 'TIN',
            enableSorting: false,
            Header: (
              <Tooltip title={tooltipValues.tin}>
                <TableText textType={TABLE_TEXT_TYPES.columnHeader}>TIN</TableText>
              </Tooltip>
            ),
            muiTableHeadCellProps: {
              sx: { borderBottom: 'none' },
            },
            muiTableBodyCellProps: {
              sx: { ...baseCellStyles },
            },
            size: 150,
            accessorFn: item =>
              item?.entityDetails?.tin ? <TableText>{item.entityDetails.tin}</TableText> : <NoData />,
          },
          {
            id: 'entityDetails.reportedStatus',
            header: 'Report Status',
            enableSorting: false,
            Header: (
              <Tooltip title={tooltipValues.reportStatus}>
                <TableText textType={TABLE_TEXT_TYPES.columnHeader}>Report Status</TableText>
              </Tooltip>
            ),
            muiTableBodyCellProps: {
              sx: baseCellStyles,
            },
            size: 180,
            accessorFn: item =>
              item?.entityDetails?.reportedStatus ? (
                <ReportedStatus reportedStatus={item.entityDetails.reportedStatus} id={item.id} />
              ) : (
                <NoData />
              ),
          },
        ],
      },
      {
        header: 'Activity Details',
        Header: <TableText textType={TABLE_TEXT_TYPES.groupHeader}>Activity Details</TableText>,
        columns: [
          {
            id: 'overviewDetails.entities',
            header: 'Linked Entities',
            Header: <TableText textType={TABLE_TEXT_TYPES.columnHeader}>Linked Entities</TableText>,
            enableSorting: false,
            muiTableBodyCellProps: {
              sx: baseCellStyles,
            },
            size: 180,
            accessorFn: item => {
              if (Array.isArray(item?.overviewDetails?.entities) && item?.overviewDetails?.entities.length > 1) {
                return (
                  <Tooltip title={item?.overviewDetails?.entities.join(', ')}>
                    <Stack direction='row' spacing={1}>
                      <TableText>{item?.overviewDetails?.entities[0]}</TableText>
                      <TableText>{`+ ${item?.overviewDetails?.entities.length - 1}`}</TableText>
                    </Stack>
                  </Tooltip>
                )
              }

              if (Array.isArray(item?.overviewDetails?.entities) && item?.overviewDetails?.entities.length === 1) {
                return <TableText>{item?.overviewDetails?.entities[0]}</TableText>
              }

              return NOT_AVAILABLE
            },
          },
          {
            id: 'overviewDetails.roles',
            header: 'Officer role',
            Header: <TableText textType={TABLE_TEXT_TYPES.columnHeader}>Officer role</TableText>,
            enableSorting: false,
            muiTableBodyCellProps: {
              sx: baseCellStyles,
            },
            size: 180,
            accessorFn: item => {
              if (Array.isArray(item?.overviewDetails?.roles) && item?.overviewDetails?.roles.length > 1) {
                return (
                  <Tooltip title={item?.overviewDetails?.roles.join(', ')}>
                    <Stack direction='row' spacing={1}>
                      <TableText>{item?.overviewDetails?.roles[0]}</TableText>
                      <TableText>{`+ ${item?.overviewDetails?.roles.length - 1}`}</TableText>
                    </Stack>
                  </Tooltip>
                )
              }

              if (Array.isArray(item?.overviewDetails?.roles) && item?.overviewDetails?.roles.length === 1) {
                return <TableText>{item?.overviewDetails?.roles[0]}</TableText>
              }

              return NOT_AVAILABLE
            },
          },
          {
            id: 'overviewDetails.firstActivity',
            header: 'First Activity',
            Header: (
              <Tooltip title={tooltipValues.firstActivity}>
                <TableText textType={TABLE_TEXT_TYPES.columnHeader}>First Activity</TableText>
              </Tooltip>
            ),
            muiTableBodyCellProps: {
              sx: baseCellStyles,
            },
            size: 180,
            accessorFn: item => (
              <TableText>
                {isNumber(item?.overviewDetails?.firstActivity) || /^\d{4}$/.test(item?.overviewDetails?.firstActivity)
                  ? item?.overviewDetails?.firstActivity
                  : formattedDate(item?.overviewDetails?.firstActivity, 'MMM. YYYY')}
              </TableText>
            ),
          },
          {
            id: 'overviewDetails.lastActivity',
            header: 'Last Activity',
            Header: (
              <Tooltip title={tooltipValues.lastActivity}>
                <TableText textType={TABLE_TEXT_TYPES.columnHeader}>Last Activity</TableText>
              </Tooltip>
            ),
            muiTableBodyCellProps: {
              sx: baseCellStyles,
            },
            size: 180,
            accessorFn: item => (
              <TableText>
                {item?.overviewDetails?.lastActivity !== null
                  ? isNumber(item?.overviewDetails?.lastActivity) || /^\d{4}$/.test(item?.overviewDetails?.lastActivity)
                    ? item?.overviewDetails?.lastActivity
                    : formattedDate(item?.overviewDetails?.lastActivity, 'MMM. YYYY')
                  : 'No last Activity'}
              </TableText>
            ),
          },
          {
            id: 'contactInfo.addresses',
            header: 'Addresses',
            Header: <TableText textType={TABLE_TEXT_TYPES.columnHeader}>Addresses</TableText>,
            enableSorting: false,
            muiTableBodyCellProps: {
              sx: baseCellStyles,
            },
            size: 350,
            accessorFn: item => {
              if (Array.isArray(item?.contactInfo?.addresses) && item?.contactInfo?.addresses.length > 1) {
                return (
                  <Tooltip title={item?.contactInfo?.addresses.join(', ')}>
                    <Stack direction='row' spacing={1}>
                      <TableText>{item?.contactInfo?.addresses[0]}</TableText>
                      <Box sx={{ width: '30px' }}>
                      <TableText >{`+ ${item?.contactInfo?.addresses.length - 1}`}</TableText>
                      </Box>
                    </Stack>
                  </Tooltip>
                )
              }

              if (Array.isArray(item?.contactInfo?.addresses) && item?.contactInfo?.addresses.length === 1) {
                return <TableText>{item?.contactInfo?.addresses[0]}</TableText>
              }

              return NOT_AVAILABLE
            },
          },
          {
            id: 'zone',
            header: 'Tax Office: Income Tax Civil',
            Header: <TableText textType={TABLE_TEXT_TYPES.columnHeader}>Tax Office: Income Tax Civil</TableText>,
            enableSorting: false,
            muiTableBodyCellProps: {
              sx: baseCellStyles,
            },
            size: 250,
            accessorFn: item => (item?.zone ? <TableText>{item?.zone}</TableText> : <></>),
          },
          {
            id: 'overviewDetails.activityType',
            header: 'Activity Type',
            Header: <TableText textType={TABLE_TEXT_TYPES.columnHeader}>Activity Type</TableText>,
            enableSorting: false,
            muiTableBodyCellProps: {
              sx: baseCellStyles,
            },
            size: 180,
            accessorFn: item =>
              item?.overviewDetails?.activityType ? <TableText>{item?.overviewDetails.activityType}</TableText> : <></>,
          },
          {
            id: 'caseNumber',
            header: 'Case ID',
            Header: (
              <Tooltip title={tooltipValues.caseID}>
                <TableText textType={TABLE_TEXT_TYPES.columnHeader}>Case ID</TableText>
              </Tooltip>
            ),
            muiTableBodyCellProps: {
              sx: baseCellStyles,
            },
            size: 180,
            accessorFn: item => (item?.caseId ? <TableText>{item?.caseId}</TableText> : <div></div>),
          },
        ],
      },
    ],
    [baseCellStyles],
  )

  return columns
}
