import React, { FC, useMemo, useState, useEffect } from 'react'
import { selectCurrentZone } from '../../store/global'
import NavigationHeader from 'components/NavigationHeader/NavigationHeader'
import GridLoader from 'components/GridLoader/GridLoader'
import TableActionsBars from 'components/verticals/TableActionsBar/TableActionsBar'

import { CurrentZone } from 'utils/types'

import { useCDTFAOverviewQuery } from './useCDTFAOverviewQuery'

import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import cx from 'classnames'
import messages from './messages'

import styles from './CDTFATablePage.scss'
import { getUrlPageValues, removeKeywordSuffix } from '../VerticalPage/common/utils'
import CDTFATable from 'components/cdtfa/CDTFATable'
import { CDTFAEntityForTable } from 'containers/CDTFATablePage/types'
import CDTFATablePageOverview from '../../components/cdtfa/CDTFATablePageOverview'
import { NOT_AVAILABLE } from 'utils/constants'
import { Filter, FilterAdditionalData } from 'components/FilterDrawer/FilterDrawer.config'
import useVerticalSearchAndSort from 'hooks/useVerticalSearchAndSort'
import { useFilterOptionsQuery } from 'hooks/useFilterOptionsQuery'
import { filtersInitialState } from './CDTFATablePage.config'
import { useCDTFAMainTableQuery } from 'containers/CDTFATablePage/useCDTFAMainTableQuery'
import { useDebounce } from 'hooks/useDebounce'

import { ISelectedColumn } from 'components/verticals/VerticalTableBase/types'
import { MRT_ColumnDef } from 'material-react-table'
import { useEmptyColumnsQuery } from 'hooks/useEmptyColumnsQuery'

const csvFileName = 'CDTFA'

const CDTFATablePage: FC = () => {
  const currentZone: CurrentZone = useSelector(selectCurrentZone)
  const [mergedColumns, setMergedColumns] = useState<MRT_ColumnDef<CDTFAEntityForTable>[]>([])
  const [selectedColumns, setSelectedColumns] = useState<ISelectedColumn[]>([])

  const allColumns = useMemo(() => mergedColumns?.flatMap(column => column ?? []), [mergedColumns]) || []

  const { searchValue, searchValueDebounced, sorting, setSearchValue, setSorting } = useVerticalSearchAndSort()

  const [activeFilters, setActiveFilters] = useState<Filter[]>([])
  const [filterAdditionalData, setFilterAdditionalData] = useState<FilterAdditionalData[]>([])

  const activeFiltersDebounced = useDebounce(activeFilters, 500)

  const { data: emptyColumns } = useEmptyColumnsQuery({
    verticalIndices: currentZone?.cdtfaIndices,
    currentQueryKey: 'fatca-entities-key',
    keys: removeKeywordSuffix(allColumns.map(el => el.id || '').filter(key => Boolean(key))),
  })

  // Fetch overview data
  const { data: verticalOverviewData, isFetching: isFetchingOverview } = useCDTFAOverviewQuery({
    verticalIndices: currentZone?.cdtfaIndices,
    currentQueryKey: 'cdtfa-overview-key',
    searchValue: searchValueDebounced,
    filters: activeFiltersDebounced,
  })

  // Fetch entities data
  const { verticalEntities, fetchNextPage, isFetching, isLoading } = useCDTFAMainTableQuery<CDTFAEntityForTable>({
    verticalIndices: currentZone?.cdtfaIndices,
    currentQueryKey: 'cdtfa-entities-key',
    searchValue: searchValueDebounced,
    filters: activeFiltersDebounced,
    sorting,
  })

  const { filterOptions } = useFilterOptionsQuery({
    verticalIndices: currentZone?.cdtfaIndices,
    currentQueryKey: 'cdtfa-filter-options-key',
    filtersInitialState,
  })

  useEffect(() => {
    if (verticalOverviewData && filterAdditionalData.length === 0) {
      setFilterAdditionalData([
        {
          name: 'aggregatedTotals.qtr_yield_ivix',
          minLimit: verticalOverviewData.summary.ivixAdjustedTaxRange.min,
          maxLimit: verticalOverviewData.summary.ivixAdjustedTaxRange.max,
        },
      ])
    }
  }, [verticalOverviewData, filterAdditionalData])

  return (
    <div className={styles.container}>
      <nav className={styles.navigationHeaderWrapper}>
        <NavigationHeader />
      </nav>
      <header className='page-header withNavigationHeader'>
        <h1 className={cx('page-title', 'h1')}>
          <FormattedMessage {...messages.header} />
        </h1>
      </header>
      <main className={styles.main}>
        <div className={styles.contentWrapper}>
          <section className={styles.summary}>
            <CDTFATablePageOverview
              isLoading={isFetchingOverview}
              summary={{
                sitesCount: { ...verticalOverviewData?.summary?.sitesCount, total: verticalOverviewData?.total?.value },
                accountsCount: {
                  ...verticalOverviewData?.summary?.accounts,
                },
                rawTaxChart: verticalOverviewData?.summary?.rawTaxChart,
              }}
            />
          </section>
          <div className={cx(styles.content, { [styles.withFilters]: false })}>
            <div className={styles.gridContainer}>
              <TableActionsBars
                countMessages={messages.count}
                displayedCount={verticalEntities ? verticalEntities.length : null}
                totalCount={verticalOverviewData?.total?.value ?? NOT_AVAILABLE}
                zoneEndpoint={currentZone?.cdtfaIndices}
                csvFileName={csvFileName}
                type={getUrlPageValues()[0]}
                withExport={true}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                activeFilters={activeFilters}
                setActiveFilters={setActiveFilters}
                filtersInitialState={filtersInitialState}
                filterOptions={filterOptions}
                filterAdditionalData={filterAdditionalData}
                mergedColumns={mergedColumns}
                selectedColumns={selectedColumns}
                setSelectedColumns={setSelectedColumns}
                allColumns={allColumns}
                emptyColumns={emptyColumns}
              />
              {isLoading ? (
                <GridLoader />
              ) : (
                <section className={styles.grid}>
                  <CDTFATable
                    isFetching={isFetching}
                    fetchNextPage={fetchNextPage}
                    totalFetched={verticalEntities?.length || 0}
                    totalDBRowCount={verticalOverviewData?.total?.value}
                    verticalEntities={verticalEntities}
                    sorting={sorting}
                    setSorting={setSorting}
                    setMergedColumns={setMergedColumns}
                    selectedColumns={selectedColumns}
                    allColumns={allColumns}
                  />
                </section>
              )}
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}

export default CDTFATablePage
