import React, { useCallback } from 'react'
import { PieChart, Pie, Cell, Label } from 'recharts'
import { PieChartData } from 'utils/types'
import styles from 'containers/OffshoreTablePage/BusinessSummary/BusinessSummary.module.scss'
import { PieSize } from 'components/common/types'

interface PieChartOptions {
  colors: string[]
  pieSize: PieSize
  uiValue?: string
  tooltipLabel?: string
  fullLabel?: string
  pieTitle?: string
  pieSubTitle?: string
  pieDy?: number
}

interface VerticalSummaryPieChartProps {
  chartData: PieChartData[]
  key: string
  options: PieChartOptions
}

const VerticalSummaryPieChart = ({ chartData, key, options }: VerticalSummaryPieChartProps) => {
  const renderCells = useCallback(
    () => options?.colors?.map(color => <Cell key={color} fill={color} stroke={color} />),
    [options],
  )

  return (
    <PieChart width={options.pieSize.size} height={options.pieSize.size}>
      <Pie
        data={chartData.map(item => ({ id: key, name: item?.name, value: item?.value }))}
        cx='50%'
        cy='50%'
        innerRadius={options.pieSize.innerRadius}
        outerRadius={options.pieSize.outerRadius}
        paddingAngle={5}
        dataKey='value'
        isAnimationActive={false}
      >
        {renderCells()}
        <Label value={options?.pieTitle} position='center' className={styles.pieNumberLabel} fill='#fff' dy={-12} />
        <Label
          value={options?.pieSubTitle}
          position='center'
          className={styles.pieDescriptionLabel}
          fill='#fff'
          dy={options?.pieDy || 12}
          width={100}
        />
      </Pie>
    </PieChart>
  )
}

export default VerticalSummaryPieChart
