import { MRT_SortingState } from 'material-react-table'
import axios from 'services/axios'
import apiService from './api'
import { Filter } from 'components/FilterDrawer/FilterDrawer.config'

export const fetchVerticalTableData = async (
  zoneEndpoint: string,
  from: number = 0,
  size: number = 100,
  searchValue?: string,
  sorting?: MRT_SortingState,
  filters?: Filter[],
) => {
  const endpoint = 'verticalsTable'
  if (!zoneEndpoint) return

  const params = {
    from,
    size,
    zoneEndpoint,
    searchValue,
    sorting,
    filters,
  }
  const config = {
    params,
  }

  try {
    const { data } = await axios.get(endpoint, config)
    return data
  } catch (err) {
    apiService.handleApiError(err as object | undefined)
    return null
  }
}

export const fetchCdtfaTableData = async (
  zoneEndpoint: string,
  from: number = 0,
  size: number = 100,
  searchValue?: string,
  sorting?: MRT_SortingState,
  filters?: Filter[],
  signal?: AbortSignal,
) => {
  const endpoint = 'verticalsTable/cdtfaTableData'
  if (!zoneEndpoint) return

  const params = {
    from,
    size,
    zoneEndpoint,
    searchValue,
    sorting,
    filters,
  }
  const config = {
    params,
    signal,
  }

  try {
    const { data } = await axios.get(endpoint, config)
    return data
  } catch (err) {
    apiService.handleApiError(err as object | undefined)
    return null
  }
}

export const fetchCompanyOverviewTableData = async (
  zoneEndpoint: string,
  from: number = 0,
  size: number = 100,
  searchValue?: string,
  sorting?: MRT_SortingState,
  filters?: Filter[],
  signal?: AbortSignal,
) => {
  const endpoint = 'companyOverview/mainTableData'
  if (!zoneEndpoint) return

  const params = {
    from,
    size,
    zoneEndpoint,
    searchValue,
    sorting,
    filters,
  }
  const config = {
    params,
    signal,
  }

  try {
    const { data } = await axios.get(endpoint, config)
    return data
  } catch (err) {
    apiService.handleApiError(err as object | undefined)
    return null
  }
}

export const fetchVerticalTableOverviewData = async (
  zoneEndpoint: string,
  searchValue?: string,
  filters?: Filter[],
) => {
  const endpoint = 'verticalsTable/overview'
  if (!zoneEndpoint) return

  const params = {
    zoneEndpoint,
    searchValue,
    filters,
  }
  const config = {
    params,
  }

  try {
    const { data } = await axios.get(endpoint, config)
    return data
  } catch (err) {
    apiService.handleApiError(err as object | undefined)
    return null
  }
}

export const fetchOffshoreTableOverviewData = async (
  zoneEndpoint: string,
  searchValue?: string,
  filters?: Filter[],
) => {
  const endpoint = 'verticalsTable/offshore-overview'
  if (!zoneEndpoint) return

  const params = {
    zoneEndpoint,
    searchValue,
    filters,
  }
  const config = {
    params,
  }

  try {
    const { data } = await axios.get(endpoint, config)
    return data
  } catch (err) {
    apiService.handleApiError(err as object | undefined)
    return null
  }
}

export const fetchFatcaTableOverviewData = async (zoneEndpoint: string, searchValue?: string, filters?: Filter[]) => {
  const endpoint = 'verticalsTable/fatca-overview'
  if (!zoneEndpoint) return

  const params = {
    zoneEndpoint,
    searchValue,
    filters,
  }
  const config = {
    params,
  }

  try {
    const { data } = await axios.get(endpoint, config)
    return data
  } catch (err) {
    apiService.handleApiError(err as object | undefined)
    return null
  }
}

export const fetchCDTFATableOverviewData = async (
  zoneEndpoint: string,
  searchValue: string,
  filters?: Filter[],
  signal?: AbortSignal,
) => {
  const endpoint = 'verticalsTable/cdtfaOverview'
  if (!zoneEndpoint) return

  const params = {
    zoneEndpoint,
    searchValue,
    filters,
  }
  const config = {
    params,
    signal,
  }

  try {
    const { data } = await axios.get(endpoint, config)
    return data
  } catch (err) {
    apiService.handleApiError(err as object | undefined)
    return null
  }
}

export const fetchCompanyOverviewTableOverviewData = async (
  zoneEndpoint: string,
  searchValue: string,
  filters?: Filter[],
  signal?: AbortSignal,
) => {
  const endpoint = 'companyOverview/mainOverview'
  if (!zoneEndpoint) return

  const params = {
    zoneEndpoint,
    searchValue,
    filters,
  }
  const config = {
    params,
    signal,
  }

  try {
    const { data } = await axios.get(endpoint, config)
    return data
  } catch (err) {
    apiService.handleApiError(err as object | undefined)
    return null
  }
}

export const exportEntitiesCSV = async (
  zoneEndpoint: string,
  _source: { includes: string[] },
  type?: string,
  selectedIds?: Array<string>,
) => {
  const endpoint = 'verticalsTable/exportEntitiesCSV'
  if (!zoneEndpoint) return

  const body = {
    zoneEndpoint,
    _source,
    type,
    selectedIds,
  }

  try {
    const { data } = await axios.post(endpoint, body)
    return data
  } catch (err) {
    apiService.handleApiError(err as object | undefined)
    return null
  }
}

export const fetchFilterOptions = async (zoneEndpoint: string, filtersInitialState: Filter[]) => {
  const endpoint = 'verticalsTable/filterOptions'
  if (!zoneEndpoint) return

  const params = {
    zoneEndpoint,
    filtersInitialState,
  }
  const config = {
    params,
    skipKeysConversion: true,
  }

  try {
    const { data } = await axios.get(endpoint, config)
    return data
  } catch (err) {
    apiService.handleApiError(err as object | undefined)
    return null
  }
}

export const fetchEmptyTableValues = async (zoneEndpoint: string, keys: string[]) => {
  const endpoint = 'verticalsTable/get-empty-table-columns'
  if (!zoneEndpoint) return

  const params = {
    zoneEndpoint,
  }
  const config = {
    params,
  }

  const body = { keys }

  try {
    const { data } = await axios.post(endpoint, body, config)
    return data
  } catch (err) {
    apiService.handleApiError(err as object | undefined)
    return null
  }
}
