import React, { useMemo } from 'react'
import styles from './CompanyOverviewTablePage.scss'
import VerticalSummaryPieChart from 'components/common/VerticalSummaryPieChart'
import { PieChartData } from 'utils/types'
import { ICompanyOverviewSummary, SummaryTitles } from 'containers/CompanyOverviewTablePage/types'
import { PIE_LEGENDS_COLORS } from 'containers/CompanyOverviewTablePage/utils'
import { getAbbreviatedNumberString } from 'components/Income/Income'
import { NOT_AVAILABLE } from 'utils/constants'
import DetailedChartLegends from 'components/common/DetailedChartLegends'
import { getPercentage } from 'utils/getPercentage'
import GenericHeading from 'components/common/GenericHeading'
import Stack from '@mui/material/Stack'
import { useTheme } from '@mui/material/styles'
import { useWindowSize } from 'hooks/useWindowSize'
import { stringToColor } from 'containers/OffshoreTablePage/BusinessSummary/utils'
import { PieChartContainer } from 'containers/CompanyOverviewTablePage/styles'
import { VerticalSummaryLoader } from 'containers/VerticalPage/common/Loaders'
import Tooltip from '@mui/material/Tooltip'
import InfoIcon from '@mui/icons-material/Info'

interface CompanyOverviewSummaryProps {
  summaryData: ICompanyOverviewSummary
}

const CompanyOverviewSummary = ({ summaryData }: CompanyOverviewSummaryProps) => {
  const theme = useTheme()
  const { width } = useWindowSize()

  const getPieTitle = (totalAmount?: number) => {
    if (typeof totalAmount !== 'number') return NOT_AVAILABLE
    return getAbbreviatedNumberString(totalAmount)
  }

  const getLegendsData = (
    chartDataItems: PieChartData[],
    totalAmount: number,
    summaryKey: keyof ICompanyOverviewSummary,
  ) => {
    if (!chartDataItems || totalAmount === undefined || totalAmount === null) {
      return null
    }
    return chartDataItems.map((item, index) => ({
      name: item.name,
      percent: getPercentage(item.value, totalAmount, 1),
      color: PIE_LEGENDS_COLORS?.[summaryKey]?.[index] || stringToColor(item.name),
    }))
  }

  const getMoreResultLegend = (moreResultsCount: number) => {
    if (moreResultsCount === undefined || moreResultsCount === null) {
      return null
    }

    if (moreResultsCount < 1) {
      return null
    }

    return `+${getAbbreviatedNumberString(moreResultsCount)} more`
  }

  const pieSize = useMemo(() => {
    if (width <= (theme.breakpoints?.values?.lgPlus || 0)) {
      return {
        size: 110,
        innerRadius: 48,
        outerRadius: 53,
      }
    }

    return {
      size: 145,
      innerRadius: 66,
      outerRadius: 72,
    }
  }, [width])

  const summaries = useMemo(() => (summaryData ? Object.entries(summaryData) : null), [summaryData])

  if (!summaries) {
    return (
      <section className={styles.summaryContainer}>
        <VerticalSummaryLoader />
      </section>
    )
  }

  return (
    <section className={styles.summaryContainer}>
      {summaries &&
        summaries.map(([key, value], index) => {
          const summaryKey = key as keyof ICompanyOverviewSummary
          const summaryValue = value as ICompanyOverviewSummary[keyof ICompanyOverviewSummary]
          return (
            <Stack
              key={key}
              direction='row'
              sx={{
                bgcolor: theme.palette.secondary.main,
                width: `${100 / summaries.length}%`,
                borderTopLeftRadius: index === 0 ? '8px' : 'none',
                borderBottomLeftRadius: index === 0 ? '8px' : 'none',
                borderTopRightRadius: index === summaries.length - 1 ? '8px' : 'none',
                borderBottomRightRadius: index === summaries.length - 1 ? '8px' : 'none',
                ...PieChartContainer,
              }}
              className={styles.data}
            >
              <VerticalSummaryPieChart
                chartData={summaryValue?.pieChartData || []}
                key={summaryKey}
                options={{
                  colors: PIE_LEGENDS_COLORS[summaryKey],
                  pieTitle: getPieTitle(summaryValue.total),
                  pieSubTitle: SummaryTitles[summaryKey],
                  pieSize,
                }}
              />
              <Stack>
                <GenericHeading
                  sx={{
                    textTransform: 'uppercase',
                    mb: 1,
                    fontSize: '1.25em',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    gap: 2,
                  }}
                >
                  {SummaryTitles[summaryKey]}
                  {key === 'companies' && (
                    <>
                      <Tooltip
                        arrow
                        title='Locations based on Headquarters jurisdictions'
                        componentsProps={{
                          tooltip: {
                            sx: {
                              border: '1px solid #DAD8DF',
                              borderRadius: '8px',
                              backgroundColor: 'white',
                              color: 'black',
                              p: 1,
                              '& .MuiTooltip-arrow': {
                                color: 'common.white',
                              },
                            },
                          },
                        }}
                      >
                        <InfoIcon sx={{ fontSize: '16px' }} />
                      </Tooltip>
                    </>
                  )}
                </GenericHeading>
                <DetailedChartLegends
                  legendsData={getLegendsData(summaryValue?.pieChartData, summaryValue?.total, summaryKey) || []}
                  moreResultsCount={getMoreResultLegend(summaryValue?.moreResultsCount)}
                />
              </Stack>
            </Stack>
          )
        })}
    </section>
  )
}

export default CompanyOverviewSummary
