import React, { FC, useMemo } from 'react'

import { NFTEntityForTable } from 'containers/NFTTablePage/types'
import VerticalTableBase from 'components/verticals/VerticalTableBase/VerticalTableBase'
import { VerticalTableCMPBaseProps } from 'components/verticals/VerticalTableBase/types'
import TableText from 'components/common/TableText'
import Income from 'components/Income'

import { MRT_ColumnDef } from 'material-react-table'
import { TABLE_TEXT_TYPES } from 'utils/types'
import { useBaseCellStyles } from 'components/verticals/VerticalTableBase/useBaseCellStyles'
import Tooltip from 'components/Tooltip'
import { NOT_AVAILABLE } from 'utils/constants'

const tooltipValues = {
  totalRevenue: 'Sum of Total sales + Total royalties',
  totalSales: 'Sum of all the sales IVIX documented for the profile',
  totalRoyalties: 'Sum of all the royalties IVIX documented for the profile',
  totalBuys: 'Sum of all the buys IVIX documented for the profile',
  location: 'The entity geographic location as identified by IVIX',
  grossProfit: 'The gross profit',
}

const NFTTable: FC<VerticalTableCMPBaseProps<NFTEntityForTable>> = ({
  verticalEntities,
  fetchNextPage,
  isFetching,
  totalDBRowCount,
  totalFetched,
  sorting,
  setSorting,
  setMergedColumns,
  selectedColumns,
  allColumns,
}) => {
  const baseCellStyles = useBaseCellStyles()
  const revenueStyles = {
    muiTableHeadCellProps: {
      sx: {
        borderBottom: 'none',
      },
    },
    muiTableBodyCellProps: {
      sx: {
        ...baseCellStyles,
      },
    },
    size: 150,
  }
  const columns = useMemo<MRT_ColumnDef<NFTEntityForTable>[]>(
    () => [
      {
        header: 'Revenue',
        Header: <TableText textType={TABLE_TEXT_TYPES.groupHeader}>Revenue</TableText>,
        columns: [
          {
            id: 'totals.grossProfit',
            header: 'Gross Profit',
            Header: (
              <Tooltip title={tooltipValues.grossProfit}>
                <TableText textType={TABLE_TEXT_TYPES.columnHeader}>Gross Profit</TableText>
              </Tooltip>
            ),
            accessorFn: item => (
              <TableText>
                {item?.totals?.grossProfit ? <Income value={item.totals.grossProfit} /> : NOT_AVAILABLE}
              </TableText>
            ),
            ...revenueStyles,
          },
          {
            id: 'totals.documentedSales',
            header: 'Total Sales',
            Header: (
              <Tooltip title={tooltipValues.totalSales}>
                <TableText textType={TABLE_TEXT_TYPES.columnHeader}>Total Sales</TableText>
              </Tooltip>
            ),
            accessorFn: item => (
              <TableText>
                {item?.totals?.documentedSales ? <Income value={item.totals.documentedSales} /> : NOT_AVAILABLE}
              </TableText>
            ),
            ...revenueStyles,
          },
          {
            id: 'totals.documentedRoyalties',
            header: 'Total Royalties',
            Header: (
              <Tooltip title={tooltipValues.totalRoyalties}>
                <TableText textType={TABLE_TEXT_TYPES.columnHeader}>Total Royalties</TableText>
              </Tooltip>
            ),
            accessorFn: item => (
              <TableText>
                {item?.totals?.documentedRoyalties ? <Income value={item.totals.documentedRoyalties} /> : NOT_AVAILABLE}
              </TableText>
            ),
            ...revenueStyles,
          },
          {
            id: 'totals.documentedBuys',
            header: 'Total Buys',
            Header: (
              <Tooltip title={tooltipValues.totalRoyalties}>
                <TableText textType={TABLE_TEXT_TYPES.columnHeader}>Total Buys</TableText>
              </Tooltip>
            ),
            accessorFn: item => (
              <TableText>
                {item?.totals?.documentedBuys ? <Income value={item.totals.documentedBuys} /> : NOT_AVAILABLE}
              </TableText>
            ),
            ...revenueStyles,
          },
        ],
      },
      {
        header: 'Activity Details',
        columns: [
          {
            id: 'entityDetails.location.keyword',
            header: 'Locations',
            Header: (
              <Tooltip title={tooltipValues.location}>
                <TableText textType={TABLE_TEXT_TYPES.columnHeader}>Locations</TableText>
              </Tooltip>
            ),
            muiTableBodyCellProps: {
              sx: { ...baseCellStyles },
            },
            accessorFn: item => <TableText>{item.entityDetails.location}</TableText>,
          },
        ],
      },
    ],
    [],
  )

  return (
    <VerticalTableBase<NFTEntityForTable>
      columns={columns}
      items={verticalEntities}
      fetchNextPage={fetchNextPage}
      isFetching={isFetching}
      totalFetched={totalFetched}
      totalDBRowCount={totalDBRowCount}
      verticalTooltipValues={tooltipValues}
      sorting={sorting}
      setSorting={setSorting}
      setMergedColumns={setMergedColumns}
      selectedColumns={selectedColumns}
      allColumns={allColumns}
    />
  )
}

export default NFTTable
